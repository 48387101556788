// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* JobCard Styling */
.job-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    /* transition: transform 0.3s ease-in-out; */
}

/* .job-card:hover {
    transform: scale(1.05);
} */

/* Job Card Image */
.job-card img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover;
}

/* Job Card Text */
.job-card .job-info {
    flex-grow: 1;
    padding-left: 15px;
}

.job-info h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(5, 46, 103); /* Default title color */
    margin: 0;
}

/* Change title color to red on hover */
.job-card:hover .job-info h3 {
    color: red;
}

.job-info .category {
    font-size: 1rem;
    color: gray;
}

.job-info .posted-date {
    font-size: 0.9rem;
    color: gray;
}

/* Apply Button */
.apply-btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    margin-left: auto;
    margin-right: 10px;
    transition: background-color 0.3s ease-in-out;
}

.apply-btn:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/styles/JobCard.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,4CAA4C;AAChD;;AAEA;;GAEG;;AAEH,mBAAmB;AACnB;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA,kBAAkB;AAClB;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,sBAAsB,EAAE,wBAAwB;IAChD,SAAS;AACb;;AAEA,uCAAuC;AACvC;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,6CAA6C;AACjD;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* JobCard Styling */\n.job-card {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: #f4f4f4;\n    padding: 15px;\n    border-radius: 8px;\n    /* transition: transform 0.3s ease-in-out; */\n}\n\n/* .job-card:hover {\n    transform: scale(1.05);\n} */\n\n/* Job Card Image */\n.job-card img {\n    width: 80px;\n    height: 80px;\n    border-radius: 5px;\n    object-fit: cover;\n}\n\n/* Job Card Text */\n.job-card .job-info {\n    flex-grow: 1;\n    padding-left: 15px;\n}\n\n.job-info h3 {\n    font-size: 1.2rem;\n    font-weight: bold;\n    color: rgb(5, 46, 103); /* Default title color */\n    margin: 0;\n}\n\n/* Change title color to red on hover */\n.job-card:hover .job-info h3 {\n    color: red;\n}\n\n.job-info .category {\n    font-size: 1rem;\n    color: gray;\n}\n\n.job-info .posted-date {\n    font-size: 0.9rem;\n    color: gray;\n}\n\n/* Apply Button */\n.apply-btn {\n    padding: 10px 20px;\n    background-color: #007BFF;\n    color: white;\n    text-decoration: none;\n    font-weight: bold;\n    border-radius: 5px;\n    margin-left: auto;\n    margin-right: 10px;\n    transition: background-color 0.3s ease-in-out;\n}\n\n.apply-btn:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
